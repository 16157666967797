* {
	box-sizing: border-box;
}

html {
	-webkit-text-size-adjust: 100%; // for iOS
	box-direction: normal;
	font-family: $root-font-family;
	font-size: $small-device-font-size;
	line-height: $root-line-height;
	font-weight: $root-font-weight;
}

html, body {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4 {
	font-weight: $heading-font-weight;
}

h1 {
	font-size: $h1-font-size;
	line-height: $h1-line-height;
	margin-bottom: 1rem;
}

h2 {
	font-size: $h2-font-size;
	margin-bottom: .5rem;
}

h3 {
	font-size: $h3-font-size;
}

h4 {
	font-size: $h4-font-size;
}

h5 {
	font-size: $h5-font-size;
}

figure {
	max-width: 100%;
	margin: 0;
	padding: 0;
}

%quotebox {
	padding: .6rem;
	margin-top: 1rem;
	margin-left: 0px;
	margin-right: 0px;
	//border-radius: 6px;
	border-left: 6px solid;
	border-right: 6px solid transparent;
	border-right-width: 6px;
	border-right-style: solid;
	border-right-color: rgba(0, 0, 0, 0) transparent;
	color: #7a7a7a;
}

blockquote {
	background-color: #fafafa;
	border-left-color: #e6e6e6;
	@extend %quotebox;
}

.important {
	background-color: #fbf8e8;
	border-left-color: #fee450;
	@extend %quotebox;
}

.warning {
	background-color: #f2dbdc;
	border-left-color: #ae272f;
	@extend %quotebox;
}

kbd {
	font-family: $root-font-family;
	padding: 2px 7px;
	border: 1px solid $gray-4;
	font-size: 0.8em;
	line-height: 1.4;
	background-color: #f3f3f3;
	color: $gray-9;
	box-shadow: 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 2px $white inset;
	border-radius: 3px;
	display: inline-block;
	text-shadow: 0 1px 0 $white;
	white-space: nowrap;
}

a {
	//border-bottom: 1px dotted;
	color: $link-color;
	text-decoration: none;
	word-wrap: break-word;
	&.sidebar-nav-item {
		&:hover {
			@include link-no-decoration();
		}
		&:focus {
			@include link-no-decoration();
		}
	}
	&:focus {
		@include link-hover();
		&.tag {
			@include link-no-decoration();
		}
	}
	&:hover {
		@include link-hover();
		&.tag {
			@include link-no-decoration();
		}
	}
	&.tag {
		@include link-no-decoration();
	}
	&.read-more-symbol {
		text-decoration: none;
		@include link-no-decoration();
	}
}


.content {
	padding-bottom: 2rem;
	padding-top: 2rem;
}
